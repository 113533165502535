import { useLocalstorage } from './useLocalstorage';

export const useUserState = () => {
  const { getStorage, STORAGE_KEY } = useLocalstorage();

  const isOniModeReleased = computed(
    () =>
      getStorage(STORAGE_KEY.ANSWERED_QUESTIONNAIRE) &&
      getStorage(STORAGE_KEY.RELEASED),
  );

  return { isOniModeReleased } as const;
};
